import React, { useEffect } from 'react';

//styles
import './prevent-scrolling.scss';

export default function PreventScrolling() {

    useEffect(() => {
        const handleScrollEvent = (e) => {
            // console.log(e)
            e.preventDefault()
        }
        // window.addEventListener('wheel', handleScrollEvent, { passive: false })
        window.addEventListener('touchmove', handleScrollEvent, { passive: false })
        // window.addEventListener('keydown', handleScrollEvent, { passive: false })


        return (() => {
            // window.removeEventListener('wheel', handleScrollEvent)
            window.removeEventListener('touchmove', handleScrollEvent)
            // window.removeEventListener('keydown', handleScrollEvent)

        })
    }, [])

    return (
        <div className="prevent-scrolling-main-container">

        </div>
    )
}
