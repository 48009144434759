import React from 'react';

//styles
import './forging-connection.scss';

//images
const cubeImg = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/cube-img.png';
const leftHand = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/left-hand.png';
const rightHand = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/right-hand.png';
const connectionIcon = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/connection-icon.png';
const Vector1 = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/vector4.png';
const Vector2 = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/vector4.png';
const stopRibbon = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/Frame894.png';
const stopRibbon2 = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/Frame893.png';



export default function ForgingConnection({ PageProgress, Progress }) {

    const progress1 = Progress[0]
    const progress2 = Progress[1]
    const progress3 = Progress[2]
    const progress4 = Progress[3]



    return (
        <div className="forging-connection-main-container"
            style={{
                top: PageProgress > progress1 && '0',
                transform: PageProgress > progress3 && 'scale(0.018) translateX(20%)',

                // transition: PageProgress > progress2 && PageProgress < progress3 ? '2.8s ease-out 3.5s' :
                //     PageProgress >= progress3 && '0.8s ease-out',

                marginLeft: PageProgress > progress3 && PageProgress < progress4 ? '-35vw' :
                    PageProgress >= progress4 && '-25vw',

                marginTop: PageProgress >= progress4 && '-17.5vh',

                opacity: PageProgress > progress4 && '0'

            }}
        >
            <div className="forging-connection-main">
                <div className="upper-layer-div"
                    style={{
                        opacity: PageProgress > progress2 && '1'
                    }}
                >

                    <div className="stop-ribbon4-div">
                        <img src={stopRibbon} alt="" className='ribbon-img'
                            style={{
                                clip: PageProgress > progress2 && 'rect(0px, 120vw, 10vw, 0px)',
                                transitionDelay: PageProgress < progress2 && '0s',

                            }}
                        />
                    </div>

                    <div className="stop-ribbon7-div">
                        <img src={stopRibbon2} alt="" className='ribbon-img'
                            style={{
                                clip: PageProgress > progress2 && 'rect(0px, 120vw, 10vw, 0vw)',
                                transitionDelay: PageProgress < progress2 && '0s',

                            }}
                        />
                    </div>
                    <div className="stop-ribbon1-div" >
                        <img src={stopRibbon} alt="" className='ribbon-img'
                            style={{
                                clip: PageProgress > progress2 && 'rect(0px, 120vw, 10vw, 0px)',
                                transitionDelay: PageProgress < progress2 && '0s',

                            }}
                        />
                    </div>

                    <div className="stop-ribbon2-div">
                        <img src={stopRibbon} alt="" className='ribbon-img'
                            style={{
                                clip: PageProgress > progress2 && 'rect(0px, 120vw, 10vw, 0vw)',
                                transitionDelay: PageProgress < progress2 && '0s',

                            }}
                        />
                    </div>
                    <div className="stop-ribbon3-div">
                        <img src={stopRibbon} alt="" className='ribbon-img'
                            style={{
                                clip: PageProgress > progress2 && 'rect(0px, 120vw, 10vw, 0vw)',
                                transitionDelay: PageProgress < progress2 && '0s',

                            }}
                        />
                    </div>

                    <div className="stop-ribbon5-div">
                        <img src={stopRibbon2} alt="" className='ribbon-img'
                            style={{
                                clip: PageProgress > progress2 && 'rect(0px, 120vw, 10vw, 0vw)',
                                transitionDelay: PageProgress < progress2 && '0s',

                            }}
                        />
                    </div>
                    <div className="stop-ribbon6-div">
                        <img src={stopRibbon2} alt="" className='ribbon-img'
                            style={{
                                clip: PageProgress > progress2 && 'rect(0px, 120vw, 10vw, 0vw)',
                                transitionDelay: PageProgress < progress2 && '0s',

                            }}
                        />
                    </div>

                </div>

                <div className="cube-container-div">
                    <img src={cubeImg} alt="" className="cube-img"
                        style={{
                            transform: PageProgress > progress1 && 'rotate(0) scale(1)'
                        }}
                    />
                    <div className="vector-div-one"
                        style={{
                            transform: PageProgress > progress1 && 'translate(-12.8vw, -2.1vw) rotate(-15deg)',
                            opacity: PageProgress > progress1 && '1',
                            transitionDelay: PageProgress < progress1 && '0s',
                            transition: PageProgress < progress1 && '0s',

                        }}
                    >
                        <img src={Vector1} alt=""
                            style={{
                                marginLeft: PageProgress > progress1 && '-5%',
                                transitionDelay: PageProgress < progress1 && '0s'
                            }}
                        />
                        <img src={Vector1} alt=""
                            style={{
                                marginLeft: PageProgress > progress1 && '-5%',
                                transitionDelay: PageProgress < progress1 && '0s'
                            }}
                        />
                        <img src={Vector1} alt=""
                            style={{
                                marginLeft: PageProgress < progress1 && '-5%',
                                transitionDelay: '0s'
                            }}
                        />
                        <img src={Vector1} alt=""
                            style={{
                                marginLeft: PageProgress > progress1 && '-5%',
                                transitionDelay: PageProgress < progress1 && '0s'
                            }}
                        />

                    </div>
                </div>

                <div className="left-hand-div"
                    style={{
                        marginLeft: PageProgress > progress1 && '0'
                    }}
                >
                    <div className="top-layer"
                        style={{
                            opacity: PageProgress > progress2 && 0,

                        }}
                    />
                    <img src={leftHand} alt="" className="hand-img" />
                </div>
                <div className="right-hand-div"
                    style={{
                        marginRight: PageProgress > progress1 && '0'
                    }}
                >
                    <div className="top-layer"
                        style={{
                            opacity: PageProgress > progress2 && 0,

                        }}
                    />
                    <img src={rightHand} alt="" className="hand-img" />

                    <div className="vector-div-two"
                        style={{
                            transform: PageProgress > progress1 && 'translate(-22vw, 2.8vw) rotate(-15deg)',
                            opacity: PageProgress > progress1 && 1,
                            transitionDelay: PageProgress < progress1 && '0s',
                            transition: PageProgress < progress1 && '0s',


                        }}
                    >
                        <img src={Vector2} alt="" />
                        <img src={Vector2} alt="" />
                        <img src={Vector2} alt="" />
                        <img src={Vector2} alt=""
                            style={{
                                marginLeft: '0.2vw'
                            }}
                        />

                    </div>
                </div>

                <div className="lower-text-content"
                    style={{
                        transform: PageProgress > progress1 && 'translate(0vw, 0vw)',
                        transitionDelay: PageProgress < progress1 && '0s'

                    }}
                >
                    <span className='span0'>Join on</span>&nbsp;
                    <span className='span1'>9th-10th April</span>&nbsp;
                
                    <div className="bottom-hr"
                        style={{
                            background: PageProgress > progress1 && 'linear-gradient(90deg, #000 0.85%, #0DF40F 44.61%)',
                            opacity: PageProgress > progress1 && '1',
                            transitionDelay: PageProgress < progress1 && '0s'


                        }}
                    />

                </div>

                <div className="upper-text-content"
                    style={{
                        transform: PageProgress > progress1 && "scale(0.8) translate(10vw)",
                        transitionDelay: PageProgress < progress1 && '0s'

                    }}
                >
                    YOUR Premier destination for <br />
                    <b>Innovation
                        <span className="span-img"
                            style={{
                                backgroundImage: `url(${connectionIcon})`
                            }}
                        />
                        <span className='bold-span'>Technology</span>
                    </b>
                    <div className="bottom-hr2"
                        style={{
                            background: PageProgress > progress1 && 'linear-gradient(90deg, #0DF40F 14.61%, #000 105%)',
                            opacity: PageProgress > progress1 && '1',
                            transitionDelay: PageProgress < progress1 && '0s'

                        }}
                    />


                </div>

            </div>


        </div>
    )
}
