import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";

//scss
import "./CalendarOne.scss";

//components
import ParaScroll from "./ParaScroll/ParaScroll";
import CalendarComp from "./CalendarComp/CalendarComp";
import EventHighlight from "./Event-Highlight-Comp/event-highlight";

//assets
const bgImg =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/map-pin-bg.png";
const greenBulb =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/green-bulb.png";
const greenSpring =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/green-spring.png";
const greenBlob =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/green-blob.png";
// const clickIcon =
//   "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/click-icon.svg";
const eventsBG =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/physical-green-bg.png";
const circleMaskedImg = "https://i.ibb.co/27SZb51/mask-circle643.png";
// const vGrid =
//   "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/v-grid.png";  //Change here
const phyImgOne =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/physical-event-img-one.png";
const phyImgTwo =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/physical-event-img-two.png";
const greenWave =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/green-wave.png";
const iconBG =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/icon-bg.svg";
const iconOne =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/icon-one.svg";
const iconTwo =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/icon-two.svg";
const iconThree =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/icon-three.svg";
const hexagonGrid =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/hexagon-grid.png";

const eventsGreenBG =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/events-bg.jpg";
const vGrid =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/v-grid.png";

const CalendarOne = ({ PageProgress, Progress }) => {
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  // eslint-disable-next-line
  const [maskVisibility, setMaskVisibility] = useState(true);
  // eslint-disable-next-line
  const [scrolling, setScrolling] = useState(0);

  const [eventHighlights, setEventHighlights] = useState(false)

  const Progress0 = Progress[15]; //0.05
  const Progress1 = Progress[16]; //0.1
  const Progress2 = Progress[17]; //0 . 1 5
  const Progress3 = Progress[18]; //0 . 2
  const Progress4 = Progress[19]; //0 . 2 5
  const Progress5 = Progress[20]; //0 . 3
  const Progress6 = Progress[21]; //0 .3 5
  const Progress7 = Progress[22]; //0 . 4
  const Progress8 = Progress[23]; //0 .4 5
  const Progress9 = Progress[24]; //0 . 5

  // const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleWindowMouseMove = (event) => {
      setCoords({
        x: event.clientX,
        y: event.clientY,
      });
    };
    window.addEventListener("mousemove", handleWindowMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleWindowMouseMove);
    };
  }, []);

  // const handleClick = () => {
  //   // navigate("/agenda");

  // };

  return (
    <div
      className="calendar-one-main-container"
      style={{
        opacity:
          PageProgress >= Progress0 && PageProgress < Progress9 ? "1" : "0",
        zIndex:
          PageProgress >= Progress0 && PageProgress < Progress9 ? "1" : "-1",
      }}
    >
      <div className="event-highlights-content-container"
        onClick={() => { setEventHighlights(false) }}
        style={{
          display: !eventHighlights && 'none'
        }}
      >
        {eventHighlights && <EventHighlight />}
      </div>
      <div className="calendar-one-main">
        <div
          className="bottom-progress-bar"
          style={{
            width:
              PageProgress < Progress0
                ? "5%"
                : PageProgress > Progress0 && PageProgress < Progress2
                  ? "20%"
                  : "100%",

            opacity: PageProgress > Progress5 && "0",
          }}
        />
        <div
          className="phygital-event-text-div"
          style={{
            transform:
              PageProgress > Progress0 && PageProgress < Progress1
                ? "translate(0vw, 0vh)"
                : PageProgress > Progress1 && PageProgress < Progress2
                  ? "translate(0vw, -5vh)"
                  : PageProgress > Progress2 && "translate(0vw, -120vh)",

            transition: PageProgress > Progress2 && "1.8s ease-out",
          }}
        >
          <p className="phygital-event-heading">
            Phy<span className="green-text">gital</span>
            <p className="event-text">event</p>
          </p>
          <div className="event-highlights-btn">
            <button
              onClick={() => { setEventHighlights(true) }}
            >Event Highlights</button>
            {/* <img src={clickIcon} alt="click icon" className="click-icon" /> */}
          </div>
        </div>
        <div
          className="phygital-event-sub-text"
          style={{
            transform:
              PageProgress < Progress1
                ? "translate(0vw, 20vh)"
                : PageProgress > Progress1 && PageProgress < Progress2
                  ? "translate(0vw, 7vh) rotate(0deg)"
                  : "translate(0vw, -100vh)",

            opacity:
              PageProgress < Progress1
                ? "0"
                : PageProgress > Progress1 && PageProgress < Progress2
                  ? "1"
                  : "0",

            color: PageProgress > Progress1 && "white",
            transition: PageProgress > Progress2 && "1.8s ease-out",
          }}
        >
          Seamless combination of digital convenience <br />
          and in-person interactions
        </div>
        <img
          src={greenBlob}
          alt="green blob"
          className="green-blob"
          style={{
            transform:
              PageProgress > Progress0 && PageProgress < Progress2
                ? "translate(-1vw, 40vh)"
                : PageProgress > Progress2 &&
                "translate(-1vw, -100vh) rotate(-180deg)",

            transition: PageProgress > Progress2 && "1.8s ease-out",
          }}
        />
        <div
          className="masking-div"
          style={{
            opacity:
              PageProgress > Progress2 && PageProgress < Progress5 ? "1" : "0",
            transform: PageProgress > Progress3 && "translate(0vw, -200vh)",
          }}
        >
          <div
            className="digital-masked-text"
            style={{
              opacity:
                PageProgress > Progress2 && PageProgress < Progress5
                  ? "0.4"
                  : "0",
            }}
          >
            <p>
              Digital
              <br />
              <span className="events-text">events</span>
            </p>
          </div>
          <img
            src={eventsBG}
            alt="masked background"
            className="masked-img"
            style={{
              WebkitMaskPosition: `${coords.x - 150}px ${coords.y - 250}px`,
              opacity: maskVisibility ? "0.5" : "0",
              maskImage: `url(${circleMaskedImg})`,
              WebkitMaskImage: `url(${circleMaskedImg})`,
            }}
          />
        </div>

        {/* <div
          className="black-curtain-one"
          style={{
            transform: PageProgress > Progress3 && "translate(0vw, -200vh)",
            opacity: PageProgress < Progress3 ? "0" : "1",
          }}
        /> */}
        <div
          className="black-curtain-two"
          style={{
            transform:
              PageProgress >= Progress2 && PageProgress < Progress3
                ? "translate(0vw, 0vh)"
                : PageProgress > Progress3 && "translate(0vw, -100vh)",
            opacity: PageProgress >= Progress2 && "1",
          }}
        />

        <div
          className="digital-physical-text-div"
          style={{
            transform:
              PageProgress > Progress2 && PageProgress < Progress5
                ? "translate(0vw, 0vh)"
                : PageProgress > Progress5 &&
                "translate(50vw, -150vh) rotate(90deg)",
          }}
        >
          <p
            className="digital-text"
            style={{
              transform: PageProgress > Progress3 && "translate(0vw, 4vh)",
              opacity: PageProgress > Progress3 && "0",
            }}
          >
            Digital
          </p>
          {/* <p
            className="ampersand"
            style={{
              opacity: PageProgress > Progress3 && "0",
            }}
          >
            &
          </p> */}
          <p
            className="eventOne-text"
            style={{
              transform: PageProgress > Progress3 && "translate(0vw, -6vh)",
              opacity: PageProgress > Progress3 && "0",
            }}
          >
            event
          </p>
          <p
            className="eventTwo-text"
            style={{
              transform: PageProgress > Progress3 && "translate(0vw, 2.5vh)",
              opacity: PageProgress > Progress3 && "1",
            }}
          >
            event
          </p>
          <p
            className="physical-text"
            style={{
              transform: PageProgress > Progress3 && "translate(0vw, -3vh)",
              opacity: PageProgress > Progress3 && "1",
            }}
          >
            Physical
          </p>
        </div>
        <div
          className="para-scroll-div"
          style={{
            transform:
              PageProgress < Progress2
                ? "translate(50vw, 80vh) rotate(-20deg)"
                : PageProgress > Progress2 && PageProgress < Progress5
                  ? "translate(0vw, 0vh)"
                  : "translate(50vw, -150vh) rotate(90deg) ",

            opacity: PageProgress > Progress5 && "0",
          }}
        >
          <ParaScroll PageProgress={PageProgress} Progress={Progress} />
          <div
            className="para-scroll-bg"
            style={{
              transform: PageProgress > Progress3 && "translate(0vw, 2vh)",
              opacity: PageProgress > Progress4 && "1",
            }}
          />
        </div>
        <div
          className="v-grid-div"
          style={{
            transform:
              PageProgress < Progress2
                ? "translate(0vw, 100vh)"
                : PageProgress > Progress2 && PageProgress < Progress3
                  ? "translate(0vw, 0vh)"
                  : "translate(0vw, -120vh)",
            opacity: PageProgress > Progress2 && "1",
          }}
        >
          <img src={vGrid} className="v-grid-img" alt="v-grid" />
        </div>
        <img
          src={eventsGreenBG}
          alt="physical event bg"
          className="physical-green-bg"
          style={{
            opacity:
              PageProgress > Progress3 && PageProgress < Progress4 ? "1" : "0",
          }}
        />
        <div
          className="overlay-second"
          style={{
            opacity:
              PageProgress > Progress2 && PageProgress < Progress6 ? "1" : "0",
          }}
        />
        <div
          className="physical-event-green"
          style={{
            opacity:
              PageProgress > Progress3 && PageProgress < Progress4
                ? "0.8"
                : "0",
          }}
        >
          <p className="physical-text">Physical</p>
          <p
            className="events-text"
            style={{
              opacity:
                PageProgress > Progress3 && PageProgress < Progress4
                  ? "1"
                  : "0",
              transform: PageProgress > Progress3 && "translate(0vw, 7vh)",
            }}
          >
            events
          </p>
        </div>
        <div
          className="physical-event-grid"
          style={{
            transform:
              PageProgress > Progress4 && PageProgress < Progress5
                ? "translate(0vw, 0vh)"
                : PageProgress > Progress5 &&
                "translate(-40vw, -100vh) rotate(-90deg)",
          }}
        >
          <div className="grid-bg-one">
            <div className="grid-bg-black-one">
              <img
                src={phyImgOne}
                alt="physical-event-one"
                className="physical-event-img-one"
              />
            </div>
          </div>

          <img
            src={greenBlob}
            alt="green blob"
            className="grid-blob"
            style={{
              transform:
                PageProgress > Progress4 && PageProgress < Progress5
                  ? "translate(0vw, 0vh)"
                  : "",
              opacity:
                PageProgress > Progress4 && PageProgress < Progress5
                  ? "1"
                  : "0",
            }}
          />

          <div
            className="grid-bg-two"
            style={{
              marginTop:
                PageProgress > Progress4 && PageProgress < Progress5
                  ? "20vh"
                  : "",
            }}
          >
            <div className="grid-bg-black-two">
              <img
                src={phyImgTwo}
                alt="physical-event-two"
                className="physical-event-img-two"
              />
            </div>
          </div>
        </div>
        <div
          className="calendar-one-bg-div"
          style={{
            backgroundImage: PageProgress > Progress5 && `url(${bgImg})`,
            opacity: PageProgress > Progress5 && "1",
            marginLeft: PageProgress > Progress6 && "-15vw",
          }}
        />
        <div
          className="overlay"
          style={{
            opacity:
              PageProgress > Progress5 && PageProgress < Progress9 ? "1" : "0",
          }}
        />
        <img
          src={greenBulb}
          alt="green bulb"
          className="green-bulb"
          style={{
            transform:
              PageProgress < Progress5
                ? "translate(0, 150vh) scale(0.5)"
                : PageProgress > Progress5 && PageProgress < Progress6
                  ? "translate(0, -47vh) scale(1) rotate(-360deg)"
                  : PageProgress > Progress6 && PageProgress < Progress7
                    ? "translate(-35vw, -47vh) rotate(-180deg)"
                    : PageProgress > Progress7 &&
                    "translate(-50vw, 0vh) rotate(-20deg) scale(0.75)",

            // marginTop:
            //   PageProgress < Progress5
            //     ? "150vh"
            //     : PageProgress > Progress5 && PageProgress < Progress7
            //     ? "-95vh"
            //     : PageProgress > Progress7 && "0vh",

            // marginLeft:
            //   PageProgress > Progress6 && PageProgress < Progress7
            //     ? "-70vw"
            //     : PageProgress > Progress7 && "-100vw",

            transition: PageProgress > Progress6 && "all 1.8s ease-in-out",
          }}
        />
        <div
          className="days-text-div"
          style={{
            transform: PageProgress > Progress5 && "translate(0, -5vh)",
          }}
        >
          <div
            className="green-dot"
            style={{
              opacity: PageProgress > Progress6 && "1",
              transform:
                PageProgress > Progress7 && "translate(-25vw, -11.7vh)",
            }}
          />
          <p
            style={{
              transform:
                PageProgress > Progress6 && PageProgress < Progress7
                  ? "translate(-35vw, 0vh)"
                  : PageProgress > Progress7 && "translate(-25vw, -10vh)",
            }}
          >
            365 <br /> <span className="days-span">DAYS</span>
          </p>
          <p
            style={{
              transform:
                PageProgress > Progress6 && PageProgress < Progress7
                  ? "translate(-5vw, 0vh)"
                  : PageProgress > Progress7 &&
                  "translate(-25vw, 0vh) scale(0.7)",
              opacity: PageProgress > Progress6 && "1",
            }}
            className="sub-text"
          >
            of immersive{" "}
            <span
              className="physical-text"
              style={{
                opacity: PageProgress > Progress8 && "0",
              }}
            >
              physical{" "}
            </span>
            <span
              className="digital-text"
              style={{
                opacity: PageProgress > Progress8 && "1",
              }}
            >
              digital
            </span>{" "}
            experience
            <div className="green-dot-one" />
            <div className="green-dot-two" />
          </p>
        </div>
        <div
          className="img-hexagon-div"
          style={{
            transform:
              PageProgress > Progress5 && PageProgress < Progress6
                ? "translate(0vw, 45vh) scale(0.8)"
                : PageProgress > Progress6 && PageProgress < Progress7
                  ? "translate(30vw, 45vh) scale(0.8)"
                  : PageProgress > Progress7 && PageProgress < Progress8
                    ? "translate(25vw, 4vh) scale(0.9)"
                    : PageProgress > Progress8 &&
                    "translate(25vw, -100vh) scale(0.9)",
          }}
        >
          <img src={hexagonGrid} alt="hexagon grid" className="hexagon-grid" />
          {/* <HexagonGrid PageProgress={PageProgress} /> */}
        </div>
        <img
          src={greenWave}
          alt="green wave"
          className="green-wave"
          style={{
            transform: PageProgress > Progress6 && "translate(0vw, 0vh)",
          }}
        />
        <img
          className="green-spring"
          alt="green-spring"
          src={greenSpring}
          style={{
            transform:
              PageProgress > Progress6 && PageProgress < Progress7
                ? "translate(-5vw, 0vh)"
                : PageProgress > Progress7 && "translate(15vw, -45vh)",
          }}
        />

        <div
          className="calendar-div"
          style={{
            transform: PageProgress > Progress8 && "translate(25vw, 4vh)",
          }}
        >
          <CalendarComp />
        </div>

        <div
          className="phygital-options-div"
          style={{
            transform: PageProgress > Progress8 && "translate(0vw, 0vh)",
            opacity: PageProgress > Progress8 ? "1" : "0",
          }}
        >
          <div className="dark-green-bg">
            <div className="white-bg-one">
              <div className="dark-green-bg-one">
                <div>
                  <p>
                    Knowledge <span className="sub-text">sharing</span>
                  </p>
                  <p className="highlighted-text">Expert Talks</p>
                </div>
                <>
                  <img src={iconBG} alt="icon bg" className="icon-bg" />
                  <img src={iconOne} alt="icon one" className="icon-one" />
                </>
              </div>
            </div>
            <div className="white-bg-two">
              <div className="dark-green-bg-two">
                <div>
                  <p>
                    Interactive <span className="sub-text">sessions &</span>
                  </p>
                  <p className="highlighted-text">Matchmaking</p>
                </div>
                <>
                  <img src={iconBG} alt="icon bg" className="icon-bg" />
                  <img src={iconTwo} alt="icon two" className="icon-two" />
                </>
              </div>
            </div>
          </div>
          <div className="green-bg">
            <div className="green-bg-sub">
              <>
                <img src={iconBG} alt="icon bg" className="icon-bg-one" />
                <img src={iconThree} alt="icon three" className="icon-three" />
              </>
              <p className="networking-text">Engagement & Networking</p>
              <>
                <img src={iconBG} alt="icon bg" className="icon-bg-two" />
                <img src={iconTwo} alt="icon four" className="icon-four" />
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarOne;
