import React, { useEffect, useState } from "react";

import "./popupfrom.scss";
import { useNavigate } from "react-router-dom";

export default function BackendForm({SetFormState}) {
  const navigate = useNavigate();
  const [isFilled, setIsFilled] = useState(false);
  if (isFilled) {
    SetFormState(false)
    navigate("/thankyou");
  }

  useEffect(() => {
    const getUrlParameter = (sParam) => {
      const sPageURL = window.location.search.substring(1);
      const sURLVariables = sPageURL.split("&");
      let sParameterName;

      for (let i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split("=");

        if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined ? true : sParameterName[1];
        }
      }
    };

    const source_value = getUrlParameter("utm_source");
    const keyword_value = getUrlParameter("utm_keyword");
    const campaign_value = getUrlParameter("utm_campaign");
    const device_value = getUrlParameter("utm_device");
    const network_value = getUrlParameter("utm_network");

    console.log(keyword_value);

    if (source_value) {
      document.getElementById("utm_source").value = source_value;
    }

    document.getElementById("utm_keyword").value = keyword_value;
    document.getElementById("utm_campaign").value = campaign_value;
    document.getElementById("utm_device").value = device_value;
    document.getElementById("utm_network").value = network_value;

    const handleSubmit = (e) => {
      e.preventDefault();

      const formData = new FormData(e.target);

      fetch("https://growtheye.com/lead_api.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.text())
        .then((res) => {
          document.getElementById("myForm")?.reset();
          setIsFilled(true);
          document.getElementById("myForm").innerHTML =
            '<p className="text-center text-white">Thank you for submitting your details!</p>';
        })
        .catch((error) => console.error("Error:", error));
    };

    document.getElementById("myForm").addEventListener("submit", handleSubmit);

    // Clean up event listener on unmount if needed
    // return () => {
    //   document.getElementById('myForm').removeEventListener('submit', handleSubmit);
    // };
  }, []);

  return (
    <div className="pform-container" >
         < div className="hidden-layer" onClick={() => { SetFormState(false) }} />
      <div className="pform-row ">
      <div className="form-close" onClick={() => SetFormState(false)}>&#x292B;</div>
        <h2 className="pcontact-heading">Join Us Today!</h2>
        <form
          className="pform_section"
          id="myForm"
          data-parsley-validate="parsley"
        >
          <div className="pform-controller">
            <div className="pcol-one">
              <input
                className="input-styling"
                type="text"
                placeholder="Full Name"
                name="name"
                required
              />
            </div>
            <div className="pcol-two">
              <input
                className="input-styling"
                type="email"
                placeholder="Enter your email"
                name="email"
                required
              />
            </div>

            <div className="pcol-three">
              <input
                className="input-styling"
                type="tel"
                placeholder="Phone number"
                name="phone"
                data-parsley-type="digits"
                data-parsley-length="[10, 13]"
                data-parsley-length-message="please enter valid number"
                required
              />
            </div>

            <div className="pcol-four">
              <input
                className="input-styling"
                type="text"
                placeholder="Company name"
                name="additional_col1"
                required
              />
            </div>

            <div className="pcol-five">
              <input
                className="input-styling"
                type="text"
                placeholder="Designation"
                name="additional_col2"
                required
              />
            </div>

            <div className="pcol-six">
              <input
                className="input-styling"
                type="text"
                placeholder="Company website"
                name="additional_col3"
                required
              />
            </div>

            <div className="pcol-seven">
              <select
                name="additional_col3_select"
                data-parsley-required-message="Please select from list"
                id=""
                className="input-styling"
                required=""
              >
                <option value="">Which Event you are interested in?</option>
                <option value="AFTC">AFTC</option>
                <option value="ETX">ETX</option>
              </select>
            </div>
            <div className="pcol-eight">
              <select
                name="additional_col1_select"
                data-parsley-required-message="Please select from list"
                id=""
                className="input-styling"
                required=""
              >
                <option value="">What are you interested in? </option>
                <option value="Sponsor">Sponsor</option>
                <option value="Attend">Attend</option>
                <option value="Partnership">Partnership</option>
              </select>
            </div>
          </div>
          <input
            type="hidden"
            name="access_code"
            value="32C5-EE1F-8568-5F9D-DC0F-0195"
          />
          <input
            type="hidden"
            className="utm_keyword"
            id="utm_keyword"
            name="utm_keyword"
            value=""
          />
          <input
            type="hidden"
            className="utm_device"
            id="utm_device"
            name="utm_device"
            value=""
          />
          <input
            type="hidden"
            className="utm_network"
            id="utm_network"
            name="utm_network"
            value=""
          />
          <input
            type="hidden"
            className="utm_campaign"
            id="utm_campaign"
            name="utm_campaign"
            value=""
          />
          <input
            type="hidden"
            className="utm_source"
            id="utm_source"
            name="source"
            value="Google"
          />

          <input type="hidden" name="link" value="" />
          <div className="">
            <div className="">
              <button type="submit" value="Submit" className="psubmit-btn">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
