import React from 'react';

//styles
import './bant-process-comp.scss';
import pppText from '../../../assets/images/logo/logo_green.png';
// library
// import { gsap } from "gsap";

//components
import BottomNavbar from '../../components/Bottom-Navbar/bottom-navbar';
import headers from '../../../assets/images/headers.png'

//images
const vector1 = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/Frame895.png';
// const bantList = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/Group942.png';
// const vector = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/Frame896.png';
// const pppText = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/Frame911.png';
const listHeading = headers;


export default function BantProcessComp({ PageProgress, Progress }) {

  const progress1 = Progress[5]
  const progress2 = Progress[6]
  const progress3 = Progress[7]
  const progress4 = Progress[8]
  const progress5 = Progress[9]
  const progress6 = Progress[10]
  const progress7 = Progress[11]
  const progress8 = Progress[12]
  const progress9 = Progress[13]
  // const progress10 = Progress[15]
  const Progress11 = Progress[16];
  const Progress12 = Progress[17];



  // const [trigger, setTrigger] = useState(false)


  // const bounceEffect = () => {
  //   gsap.from('.bant-process-comp-vector', {
  //     duration: 4.5,
  //     ease: "elastic.out(1,0.3)",
  //     y: 600,
  //     scale: 0.4,
  //     delay: 1.2
  //   });
  //   gsap.to('.bant-process-comp-vector', {
  //     duration: 4.5,
  //     ease: "elastic.out(1,0.3)",
  //     y: 0,
  //     scale: 1,
  //     delay: 1.2

  //   });
  // }


  // useMemo(() => {
  //   if (PageProgress > progress1) {
  //     setTrigger(true)
  //   }
  //   // eslint-disable-next-line
  // }, [PageProgress])

  // useMemo(() => {

  //   trigger && bounceEffect()
  // }, [trigger])




  return (
    <div className="bant-process-comp-main-container"
      style={{
        opacity: PageProgress > progress1 && '1'
      }}
    >
      {/* <p style={{
        color: "red",
        position: "absolute",
        top: "0%",
        zIndex: "100"
      }}>{PageProgress}</p> */}
      {/* -----------------------z-----------------BG-COLOR-------------------------- */}
      <div className="bg-color-div"
        style={{
          transform: PageProgress > progress2 && 'scale(1)',
          opacity: PageProgress > progress3 && '0'
        }}
      />
      {/* ------------------------------------------------------------------ */}

      {/* ------------------------------------------------------------------ */}

      <div className="vector-div-one"
        style={{
          opacity: PageProgress > progress3 && PageProgress < progress9 ? '1' :
            PageProgress > progress9 && PageProgress < Progress11 ? '0' :
              PageProgress > Progress11 && '1',
          transform: PageProgress > progress5 && PageProgress < Progress12 ? 'scale(0.8) translateY(10vh) ' :
            PageProgress > Progress12 && 'scale(0.8) translateY(-20vh)',
          // background: PageProgress > Progress11 && 'none'

        }}
      >
        <img src={vector1} alt="" className="vector-img-one"
          style={{
            transform: PageProgress > progress4 && PageProgress < progress5 ? 'rotate(-105deg)' :
              PageProgress > progress5 && PageProgress < progress6 ? 'rotate(0deg)' :
                PageProgress > progress6 && PageProgress < progress7 ? 'rotate(-105deg)' :
                  PageProgress > progress7 && PageProgress < progress8 ? 'rotate(-105deg)' :
                    PageProgress > progress8 && PageProgress < progress9 ? 'rotate(0deg)' :
                      PageProgress >= Progress11 && 'rotate(15deg)',

          }}
        />

      </div>

      <div className="vector-div-two"
        style={{
          opacity: PageProgress > progress3 && PageProgress < progress4 && '1',
          transform: PageProgress > progress5 && 'translateY(50vh)',
        }}
      >

        <img src={vector1} alt="" className="vector-img-two"
          style={{
            transform: PageProgress > progress4 && 'rotate(45deg)'
          }}
        />
      </div>
      {/* ------------------------------------------------------------------ */}

      {/* ---------------------------------MAIN CONTAINER--------------------------------- */}

      <div className="bant-process-comp-main">

        {/* ------------------------------BOTTOM NAV------------------------------------ */}

        <BottomNavbar PageProgress={PageProgress} Progress={Progress} />
        {/* ------------------------------------------------------------------ */}


        {/* -----------------------------------VECTOR ONE------------------------------- */}

        <img src={vector1} alt="" className="vector-one-img bant-process-comp-vector"
          style={{
            transition: '1.6s ease-in-out',

            marginTop: PageProgress > progress1 && PageProgress < progress2 ? '-20vh' :
              PageProgress > progress2 && '-2vw',

            transform: PageProgress > progress2 && PageProgress < progress3 ? 'scale(0.5) rotate(-90deg)' :
              PageProgress > progress3 && 'scale(0.28) rotate(-90deg) translate(18vw , -44vw)',
            opacity: PageProgress > progress3 && '0'

          }}
        />
        {/* ------------------------------------------------------------------ */}

        {/* ------------------------------------------------------------------ */}

        <div className="potential-participant-profiling-text-div"
          style={{
            transform: PageProgress > progress1 && 'translate(0vw, 0vh) scaleY(1)',
            opacity: PageProgress > progress1 && PageProgress < progress2 ? '1' :
              PageProgress > progress2 && '0',
            transition: PageProgress > progress2 && '0.8s ease-in-out'

          }}
        >

          <img src={pppText} alt="" className="potential-participant-profiling-text-img" />

        </div>

        {/* ------------------------------------------------------------------ */}


        <div className="bant-heading-div"
          style={{
            opacity: PageProgress > progress2 && '1',
            transform: PageProgress > progress2 && PageProgress < progress4 ? 'translate(0)' :
              PageProgress > progress4 && 'translate(-100vw)',
          }}
        >

          <div className="left-name"
            style={{
              color: PageProgress > progress3 && '#ffffffa2',
              fontSize: PageProgress > progress3 && '2.8vw',
              marginLeft: PageProgress < progress3 && '3vw',
              // textShadow: 'none'
            }}
          >
            LEARN

            <span className="process-span"
              style={{
                transform: PageProgress > progress2 && PageProgress < progress3 ? 'translate(0)' :
                  PageProgress > progress3 && 'translate(0, -1.2vw)',
                color: PageProgress > progress3 && '#505050',
                // fontSize: PageProgress > progress4 && '1.5vw',

              }}
            >
              

              <span className="progress-sub-span"
                style={{
                  opacity: PageProgress > progress3 && '0.3',
                  transitionDelay: PageProgress > progress3 && '0.5s'

                }}
              >
                
              </span>
            </span>
          </div>

          <div className="content-container"
            style={{
              width: PageProgress < progress3 && '0vw',
              opacity: PageProgress < progress3 && '0'

            }}
          >

            {/* <div className="table-menu-div"
              style={{
                opacity: PageProgress > progress3 && '1'
              }}
            >
              <img src={listHeading} alt="" className="list-heading" />


            </div> */}


            <div className="inner-div"
              style={{
                transform: PageProgress < progress3 && 'scaleX(0)'
              }}
            >
              <img src={listHeading} alt="" className="bant-list-img"
                style={{
                  transform: PageProgress > progress3 && 'scaleY(1)'
                }}
              />

            </div>

          </div>

          <div className="right-name"
            style={{
              color: PageProgress > progress3 && '#ffffffa2',
              fontSize: PageProgress > progress3 && '2.8vw',

            }}
          >
            UPSKILL
          </div>

        </div>

      </div>
    </div>
  )
}
