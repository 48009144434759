import React, { useState, useMemo } from 'react';

import { Link } from 'react-router-dom';

// styles
import './dynamic-circle-comp.scss';



//images
const bookNowBtn = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/book-now-btn.png';
const leftIcon = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/left-btn-icon.png';
const rightIcon = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/right-btn-icon.png';
const cardIcon = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/Frame899.png';

export default function DynamicCircleComp({ FirstCompAnime, PageProgress, Progress, FormState, SetFormState }) {

    const progress2 = Progress[0]

    const [itemId, setItemId] = useState(4);
    const [cardLink, setCardLink] = useState('')
    const [hiddenToggle, setHiddenToggle] = useState(false)


    const handleRightBtn = () => {
        itemId < 7 && itemId >= 0 && setItemId(itemId + 1)
    }
    const handleLeftBtn = () => {
        itemId <= 7 && itemId > 0 && setItemId(itemId - 1)

    }

    useMemo(() => {

        if (hiddenToggle) {
            setTimeout(() => {
                setHiddenToggle(false)
            }, 600);
        }

    }, [hiddenToggle])

    const cityCardData = [
        {
            name: '2 Events',
            data: 'Musical Night',
            linkURL: '/',
        },
        {
            name: '15+ Events',
            data: 'Cultural Eve',
            linkURL: '/',
        },
        {
            name: '20+ Events',
            data: 'Fun Zone',
            linkURL: '/',
        },
        {
            name: '5 Events',
            data: 'Entrepreneurship',
            linkURL: '/',
        },
        {
            name: '11 Events',
            data: 'Competitions',
            linkURL: '/',
        },
        {
            name: '5 Events',
            data: 'WorkShops',
            linkURL: '/',
        },

        {
            name: '5 Events',
            data: 'E-Sports',
            linkURL: '/',
        },
        {
            name: '3 Events',
            data: 'Entertainment',
            linkURL: '/',
        },

    ]

    useMemo(() => {
        const link = cityCardData.filter((item, i) => {
            return itemId === i
        })

        setCardLink(link[0].linkURL)
        // console.log(cardLink)
        // eslint-disable-next-line
    }, [itemId])
    return (
        <div className="dynamic-circle-comp-main-container"
            style={{
                transform: !FirstCompAnime && PageProgress < progress2 && 'translateY(0)',
                opacity: !FirstCompAnime && '1'
            }}
        >
            {/* <p style={{ position: 'fixed', top: '5%', color: 'red', zIndex: '10', cursor: 'pointer' }}>{itemId}</p> */}

            <div className="dynamic-circle-comp-main">
                <div className="gradient-div" />
                <div className="inner-circle-container">


                    {/* ----------------------------------------Left Button------------------------------------------------- */}
                    <button
                        className="left-btn"
                        onClick={() => {
                            handleLeftBtn()
                            setHiddenToggle(true)

                        }}
                        style={{
                            backgroundImage: `url(${leftIcon})`
                        }}
                    />
                    <div className="hidden-layer-left"
                        style={{
                            display: !hiddenToggle && 'none'
                        }}
                    />
                    {/* ----------------------------------------Right Button------------------------------------------------- */}

                    <button
                        className="right-btn"
                        onClick={() => {
                            handleRightBtn()
                            setHiddenToggle(true)

                        }}
                        style={{
                            backgroundImage: `url(${rightIcon})`
                        }}
                    />
                    <div className="hidden-layer-right"
                        style={{
                            display: !hiddenToggle && 'none'
                        }}
                    />
                    {/* ----------------------------------------Book Now Button------------------------------------------------- */}
                    <button className="book-now-btn"
                        onClick={() => { window.location.href = "https://linktr.ee/advikfest"; }}
                        style={{
                            backgroundImage: `url(${bookNowBtn})`,
                        }}
                    >
                    </button>

                    {/* ----------------------------------------CARDs------------------------------------------------- */}

                    <div className="card-container">
                        <Link className="navigation-link"
                            to={cardLink}
                            target="_blank" rel='noreferrer'
                        />
                        {
                            cityCardData.map((item, i) => {
                                return (
                                    <div
                                        className="city-card"
                                        key={i}
                                        style={{
                                            transform:
                                                i === itemId - 4 ? 'translate(-46vw,12.4vw) rotate(-30deg)' :
                                                    i === itemId - 3 ? 'translate(-36vw,7.3vw) rotate(-23deg)' :
                                                        i === itemId - 2 ? 'translate(-24vw,3.2vw) rotate(-15deg)' :
                                                            i === itemId - 1 ? 'translate(-13vw,1vw) rotate(-7.5deg)' :
                                                                i === itemId ? 'translate(0,-0.7vw) rotate(0deg)' :
                                                                    i === itemId + 1 ? 'translate(13vw,1vw) rotate(7.5deg)' :
                                                                        i === itemId + 2 ? 'translate(24vw,3.2vw) rotate(15deg)' :
                                                                            i === itemId + 3 ? 'translate(36vw,7.3vw) rotate(23deg)' :
                                                                                i === itemId + 4 ? 'translate(46vw,12.4vw) rotate(30deg)' :
                                                                                    'scale(0)'


                                        }}
                                    >
                                        <img src={cardIcon} alt="" className="card-icon"
                                            style={{
                                                transform: i === itemId && 'scale(1.5)',
                                                marginBottom: i === itemId && '0.4vw',
                                                transition: i === itemId && '1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)',
                                                animation: i === itemId && 'iconBlinkKF 1.5s ease-in-out infinite'
                                            }}
                                        />
                                        <span className="date-span"
                                            style={{
                                                transform: i === itemId && 'scale(1.5)',
                                                marginBottom: i === itemId && '0.15vw',
                                                transition: i === itemId && '1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)'

                                            }}
                                        >
                                            {item.data}
                                        </span>
                                        <span className="city-name-span"
                                            style={{
                                                transform: i === itemId && 'scale(1.2)',
                                                transition: i === itemId && '1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)'
                                            }}
                                        >
                                            {item.name}
                                        </span>
                                    </div>
                                )
                            })
                        }


                    </div>



                </div>

            </div>
        </div>
    )
}
