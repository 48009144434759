import React from 'react';

// styles
import './bottom-navbar.scss';

//img
const vector = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/Frame896.png';

export default function BottomNavbar({ PageProgress, Progress }) {
    const progress1 = Progress[5]
    const progress1_1 = Progress[7]
    const progress2 = Progress[8]
    const progress2_1 = Progress[10]
    const progress3 = Progress[11]
    // const progress4 = Progress[12]
    const progress5 = Progress[13]


    // console.log(PageProgress - progress1)

    return (
       <div></div>
    )
}
