import React, { useState, useEffect } from 'react';

//styles
import './scroll-down-comp.scss';

export default function ScrollDownComp() {


    //GIF
    const scrollDownGIF = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Catalyst/nav-bar-img/mouse-icon.gif'

    const [scrollDownIconVisibility, setScrollDownIconVisibility] = useState(false)


    useEffect(() => {
        let ScrollIconTimeOut;

        function handleMouseOverEvent() {
            setScrollDownIconVisibility(true)
            clearTimeout(ScrollIconTimeOut)

            if (scrollDownIconVisibility) {
                ScrollIconTimeOut = setTimeout(() => {
                    setScrollDownIconVisibility(false)
                }, 5000);
            }

        }


        window.addEventListener('wheel', handleMouseOverEvent);
        window.addEventListener('touchmove', handleMouseOverEvent);
        window.addEventListener('keydown', handleMouseOverEvent);
        // window.addEventListener("mousemove", handleMouseOverEvent);

        return (() => {
            window.removeEventListener('wheel', handleMouseOverEvent);
            window.removeEventListener('touchmove', handleMouseOverEvent);
            window.removeEventListener('keydown', handleMouseOverEvent);
            // window.removeEventListener("mousemove", handleMouseOverEvent);
        })

    }, [scrollDownIconVisibility])

    return (
        <div className="scroll-down-comp-main-container" >

            <span className="img-icon">
                <img src={scrollDownGIF} alt="" />
            </span>
        </div>
    )
}
