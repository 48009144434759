import React from "react";

//styles
import "./overcome-limitations-comp.scss";

//imgs
// const vector = "https://rentblob.blob.core.windows.net/agri-food-tech/home-page/vector.png";
const vector2 = "https://rentblob.blob.core.windows.net/agri-food-tech/home-page/vector5.png";

export default function OvercomeLimitationsComp({ PageProgress, Progress }) {
  const progress1 = Progress[2];
  const progress2 = Progress[3];
  const progress3 = Progress[4];

  return (
    <div
      className="overcome-limitations-comp-main-container"
      style={{
        opacity:
          PageProgress > progress1 && PageProgress < progress3 ? "1" : "0",
        // transition: PageProgress > progress1 && '2.8s ease-out 3.5s',
        zIndex:
          PageProgress > progress1 && PageProgress < progress3 ? "1" : "0",
      }}
    >
      <div className="overcome-limitations-comp-main">
        <img
          src={vector2}
          alt=""
          className="vector-img-one"
          style={{
            top: PageProgress > progress1 && PageProgress < progress2 ? "-16.5vw" :
              PageProgress > progress2 && "-22vw",
            transform:
              PageProgress >= progress2 && PageProgress < progress3
                ? "translate(-0vw)"
                : PageProgress > progress3 && "translateY(-20vw)",
            // transition: PageProgress > progress1 && PageProgress < progress2 && '1.8s ease-out 4s',
          }}
        />
        <img
          src={vector2}
          alt=""
          className="vector-img-two"
          style={{
            bottom: PageProgress > progress1 && "-15vw",
            transform: PageProgress >= progress2 && "translate(-90vw)",
            // transition: PageProgress > progress1 && PageProgress < progress2 && '1.8s ease-out 4s',
          }}
        />

        <div
          className="overcome-heading"
          style={{
            transform:
              PageProgress > progress1 && PageProgress < progress2
                ? "scale(1) translate(19.83vw, 1.2vw)"
                : PageProgress >= progress2 &&
                "scale(0.68) translate(19.83vw, -4.2vw)",

            marginLeft:
              PageProgress > progress1 && PageProgress < progress2
                ? "-35vw"
                : PageProgress >= progress2 && "-25vw",

            marginTop: PageProgress >= progress2 && "-35vh",

            transition:
              PageProgress > progress3 &&
              "1.6s cubic-bezier(.25, .08, .02, .98)",
            opacity: PageProgress > progress2 && "0",

          }}
        >
          <div className="top-text">
            <span className="span1">
              Over<span className="sub-span">come</span>
            </span>
            &nbsp;
            {/* <span className="span2">the</span>&nbsp; */}
            <span className="span3">The Limitations</span>
          </div>
          <div
            className="bottom-text"
            style={{
              transform: "translateY(0vh)",
            }}
          >
            {/* <span
              className="span1"
            >
              of
            </span> */}
            &nbsp;&nbsp;
            <span className="span2">And Embrace The</span>&nbsp;
            <span className="span3"><span className="sub-span">Growth</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
