import React from 'react';

//styles
import './strategic-data-driven.scss';

//images
// import bgImg from '../../../imgs/home-page/people-are-standing-large-room-with-lot-lights.png';
// const vector = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/vector.png';
const frame1 = 'https://imagedelivery.net/F5KOmplEz0rStV2qDKhYag/ee8ce0af-bb87-4d56-c561-1b9f7139bb00/source';
const frame2 = 'https://static.vecteezy.com/system/resources/previews/000/544/049/non_2x/green-technology-circle-and-computer-science-abstract-background-with-binary-code-matrix-business-and-connection-futuristic-and-industry-4-0-concept-internet-cyber-and-network-theme-vector.jpg';
const frame3 = 'https://techcrunch.com/wp-content/uploads/2019/03/GettyImages-1090132112.jpg';
const frame4 = 'https://img.freepik.com/premium-photo/green-drone-with-green-light-it_902639-7660.jpg';
const frame5 = 'https://img.freepik.com/fotos-premium/um-foguete-verde-com-a-palavra-foguete_803320-2417.jpg';
const vector2 = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/Frame900.png';


export default function StrategicDataDriven({ PageProgress, Progress }) {

    const progress1 = Progress[3]
    const progress2 = Progress[4]
    const progress3 = Progress[5]


    return (
        <div className="strategic-data-driven-main-container"
            style={{
                opacity: PageProgress > progress1 && '1',
                transform: PageProgress > progress3 && 'translateY(-200vh)',
                transition: PageProgress > progress3 && '2.8s ease-out',
                zIndex: PageProgress > progress1 && '2'

            }}
        >

            <div className="strategic-data-driven-main">

                <div className="main-heading-div"
                    style={{
                        transform: PageProgress > progress2 && 'translateY(-100vh)'
                    }}
                >
                    {/* ------------------------------------------------------------------------------- */}
                    <div className="heading-div"
                        style={{
                            transform: PageProgress > progress1 && 'translate(0)',
                        }}

                    >
                        <div className="top-text">
                            {/* <span className="span1">
                                With
                                <b>our</b>
                            </span>&nbsp; */}
                            <span className="span2">In The Largest TechFest Of</span>
                        </div>

                        <div className="bottom-text">
                            <span className="span1">
                                Haryana
                            </span>&nbsp;

                        </div>

                    </div>
                    {/* ------------------------------------------------------------------------------- */}

                    {/* ------------------------------------------------------------------------------- */}
                    <div className="overcome-heading"
                        style={{
                            opacity: PageProgress > progress1 && '1',
                        }}
                    >
                        <div className="top-text">
                            <span className='span1'>Overcome</span>&nbsp;
                            <span className='span2'>The</span>&nbsp;
                            <span className='span3'>Limitations</span>
                        </div>
                        <div className="bottom-text"
                            style={{
                                transform: 'translateY(0vh)'
                            }}
                        >
                            <span className="span1">And</span>&nbsp;&nbsp;
                            <span className='span2'>Embrace The</span>&nbsp;
                            <span className='span3'><span className='sub-span'>Growth</span></span>
                        </div>

                    </div>
                    {/* ------------------------------------------------------------------------------- */}
                </div>



                <div className="next-content-div"
                    style={{
                        marginTop: PageProgress > progress2 && '56vh',
                    }}
                >
                    <div className="content-text"
                        style={{
                            transform: PageProgress > progress2 && 'translateY(0) scale(1)'
                        }}
                    ><span>Advik: </span>
                    Where Limitations Fade,<br /> Innovation Thrives,<br /> and Boundaries Are Overcome!
        

                    </div>

                    <img src={vector2} alt="" className="vector2-img" />

                </div>


                <div className="carousel-div"
                    style={{
                        transform: PageProgress > progress1 && PageProgress < progress2 ? 'translate(-0vh)' :
                            PageProgress > progress2 && 'translate(0vh, -45vh)',
                        opacity: PageProgress > progress1 && '1'
                    }}
                >
                    <div className="slides-div"
                        style={{
                            transform: PageProgress > progress2 && 'translate(-35%)'
                        }}
                    >

                        <img src={frame1} alt="" className="slide-img" />
                        <img src={frame2} alt="" className="slide-img" />
                        <img src={frame3} alt="" className="slide-img" />
                        <img src={frame4} alt="" className="slide-img" />
                        <img src={frame5} alt="" className="slide-img" />


                    </div>
                </div>


            </div>
        </div>
    )
}
